import React from "react";
import images from "../../../constants/images";
import "../../../styles/Home/CounterOne.css";
import ReactOdometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";
import Fade from "react-reveal/Fade";

const CounterOne = () => {
  const [partner, setPartner] = React.useState(0);
  const [studentAssistance, setStudentAssistance] = React.useState(0);
  const [satisfiedCustomers, setSatisfiedCustomers] = React.useState(0);
  const [expertConsultants, setExpertConsultants] = React.useState(0);
  const PartnerValue = 16;
  const StudentAssistanceValue = 242;
  const SatisfiedCustomersValue = 322;
  const ExpertConsultantsValue = 5;

  React.useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector(".counters-one");
      if (element) {
        const rect = element.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;
        if (isVisible) {
          setPartner(PartnerValue);
          setStudentAssistance(StudentAssistanceValue);
          setSatisfiedCustomers(SatisfiedCustomersValue);
          setExpertConsultants(ExpertConsultantsValue);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [PartnerValue]);
  return (
    <section className="counters-one">
      <div
        className="counters-one-bg"
        style={{ backgroundImage: `url(${images.CounterOneBg})` }}></div>
      <div className="container">
        <ul className="counters-one__box list-unstyled">
          <li className="counter-one__single">
            <div className="counter-one__icon">
              <span>
                <img src={images.Partner} alt="" />
              </span>
            </div>
            <Fade>
              <h3>
                <ReactOdometer
                  value={partner}
                  format="(.ddd),dd"></ReactOdometer>
              </h3>
            </Fade>
            <p className="counter-one__text">Partners</p>
          </li>
          <li className="counter-one__single">
            <div className="counter-one__icon">
              <span>
                <img src={images.Student} alt="" />
              </span>
            </div>
            <Fade>
              <h3>
                <ReactOdometer
                  value={studentAssistance}
                  format="(.ddd),dd"></ReactOdometer>
              </h3>
            </Fade>
            <p className="counter-one__text">STUDENTS ASSISTANCE</p>
          </li>
          <li className="counter-one__single">
            <div className="counter-one__icon">
              <span>
                <img src={images.Satisfied} alt="" />
              </span>
            </div>
            <Fade>
              <h3>
                <ReactOdometer
                  value={satisfiedCustomers}
                  format="(.ddd),dd"></ReactOdometer>
              </h3>
            </Fade>
            <p className="counter-one__text">SATISFIED CUSTOMERS</p>
          </li>
          <li className="counter-one__single">
            <div className="counter-one__icon">
              <span>
                <img src={images.Consult} alt="" />
              </span>
            </div>
            <Fade>
              <h3>
                <ReactOdometer
                  value={expertConsultants}
                  format="(.ddd),dd"></ReactOdometer>
              </h3>
            </Fade>
            <p className="counter-one__text">EXPERT CONSULTANTS</p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default CounterOne;
