import React from "react";
import Mainlayout from "../../components/Mainlayout";
import PageHeader from "../../sharedComponents/PageHeader";
import { images } from "../../constants";
import B2bSolutionLayout from "../../sharedComponents/B2bSolutionLayout";
import { IoCheckmark } from "react-icons/io5";
import "../../styles/Services/ServicesSolution.css";

const FoodSolution = () => {
  return (
    <div>
      <Mainlayout>
        <PageHeader
          name1={"HOME"}
          name2={"B2B"}
          MainName={"FOOD SOLUTIONS"}
          backgroundImage={images.B2bPageHeader}
        />
        <B2bSolutionLayout>
          <div className="col-xl-8 col-lg-7">
            <div className="services-details__left">
              <div className="services-details__img">
                <img src={images.ServicesDetailImg3} alt="" />
              </div>
              <div className="services-details__top-content">
                <h2 className="services-details__top-title">FOOD SOLUTIONS</h2>
                <p className="services-details__top-text">
                  We offer an extensive food management process that encompasses
                  a formal review of food selection, preparation, display, and
                  preservation.
                </p>
              </div>
              <div className="services-details__planning">
                <div className="services-details__planning-content">
                  <h2 className="services-details__planning-title">
                    What we offer
                  </h2>
                  <p className="services-details__planning-text">
                    We offer following services to the restaurant, hotels and
                    other food industry but not limited to :
                  </p>
                  <ul className="list-unstyled services-details__planning-list">
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Preparation Techniques.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Cooking Methods.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Kitchen Management.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Food Cost Control.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Hygiene and Sanitation</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Latest Food Trends</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Kitchen Setup</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </B2bSolutionLayout>
      </Mainlayout>
    </div>
  );
};

export default FoodSolution;
