import React from "react";
import "../../../styles/Home/Industries.css";
import { Link } from "react-router-dom";
import images from "../../../constants/images";

const Industries = () => {
  return (
    <section className="industries">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6">
            <div className="section-title text-left">
              <h2 className="section-title__title">Our B2C Services</h2>
              <span className="section-title__tagline"></span>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="industries__top-text-box">
              <p className="industries__top-text">
                We help students and youths interested in hospitality &amp;
                tourism sector. We train, groom and even provide guaranteed job
                and internship assistance worldwide.
              </p>
            </div>
          </div>
        </div>
        <ul className="list-unstyled industries__content-box">
          <li className="industries__single">
            <div className="industries__icon">
              <span className="icon-consultant">
                <img src={images.consultantPng} height={65} alt="" />
              </span>
            </div>
            <h3 className="industries__title">
              <Link to={"/b2c-services#vocational"}>
                VOCATIONAL <br />
                EDUCATION
              </Link>
            </h3>
            <p className="industries__text">
              National & International Hospitality Courses on Culinary & F&B.
            </p>
          </li>
          <li className="industries__single">
            <div className="industries__icon">
              <span className="icon-consultant">
                <img src={images.consultingPng} height={65} alt="" />
              </span>
            </div>
            <h3 className="industries__title">
              <Link to={"/b2c-services#abroad"}>
                ABROAD EDUCATION & <br />
                VISA CONSULTATION
              </Link>
            </h3>
            <p className="industries__text">
              We assist interested youths and students on topics that's best for
              them.
            </p>
          </li>
          <li className="industries__single">
            <div className="industries__icon">
              <span className="icon-consultant">
                <img src={images.businessPng} height={65} alt="" />
              </span>
            </div>
            <h3 className="industries__title">
              <Link to={"/b2c-services#personal"}>
                Job & Internship <br />
                Assistance
              </Link>
            </h3>
            <p className="industries__text">
              During or Post Training Job & Internship Guarantee if criteria
              met.
            </p>
          </li>
          <li className="industries__single">
            <div className="industries__icon">
              <span className="icon-consultant">
                <img src={images.customerReview} height={65} alt="" />
              </span>
            </div>
            <h3 className="industries__title">
              <Link to={"/b2c-services#jobs"}>
                Personal Grooming & <br />
                Career Counselling
              </Link>
            </h3>
            <p className="industries__text">
              Interview Preparation, Resume Pooling & Orientation.
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Industries;
