import React from "react";
import "../../styles/Navbar.css";
import { images } from "../../constants";
import { NavLink } from "react-router-dom";
import DropDown from "./DropDownWWR";
import DropDownServices from "./DropDownServices";
import { HashLink } from "react-router-hash-link";
import { IoReorderThreeOutline } from "react-icons/io5";
import MobileSidebar from "./MobileSidebar";

const Header = ({ backgroundColor, position }) => {
  const [isNavVisible, setIsNavVisible] = React.useState(false);
  const [isServiceVisible, setIsServiceVisible] = React.useState(false);
  const [isB2cVisible, setIsB2cVisible] = React.useState(false);

  const [navMobileToggle, setNavMobileToggle] = React.useState(false);

 const handleCloseClick = () => {
    setNavMobileToggle(false);
  };

  return (
    <>
      <div
        className="main-header"
        style={{
          backgroundColor: `${backgroundColor}`,
          position: `${position}`,
        }}>
        <div className="header-logo">
          <img src={images.Logo} alt="Logo" />
        </div>
        <div className="header-items">
          <ul id="main-navbar">
            <li className="navbar-li">
              <NavLink className="navbar-a" to={"/"}>
                HOME
              </NavLink>
            </li>
            <li
              className="navbar-li"
              onMouseEnter={() => setIsNavVisible(true)}
              onMouseLeave={() => setIsNavVisible(false)}>
              <NavLink className="navbar-a">WHO WE ARE</NavLink>
              <DropDown isVisible={isNavVisible} />
            </li>
            <li
              className="navbar-li"
              onMouseEnter={() => setIsServiceVisible(true)}
              onMouseLeave={() => setIsServiceVisible(false)}>
              <NavLink className="navbar-a">SERVICES</NavLink>
              <DropDownServices
                isVisible={isServiceVisible}
                setState={setIsB2cVisible}
                state={isB2cVisible}
              />
            </li>
            <li className="navbar-li">
              <HashLink className="navbar-a" to={"/b2c-services#jobs"}>
                INTERNSHIP
              </HashLink>
            </li>
            <li className="navbar-li">
              <NavLink className="navbar-a" to={"/gallery"}>
                GALLERY
              </NavLink>
            </li>
            <li className="navbar-li">
              <NavLink className="navbar-a" to={"/contact-us"}>
                CONTACT US
              </NavLink>
            </li>
            <li className="navbar-li">
              <NavLink className="navbar-a" to={"https://career.dsailorgroup.com.au/"}>
                CAREER
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="main-info">
          Talk to an expert
          <br />
          <a href="">+977-9843313786</a>
        </div>
        <div className="mobile-slide">
          <IoReorderThreeOutline onClick={() => setNavMobileToggle(true)} />
        </div>
      </div>
      {navMobileToggle ? (
        <MobileSidebar handleCloseClick={handleCloseClick} />
      ) : (
        ""
      )}
    </>
  );
};

export default Header;
