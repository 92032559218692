import React from 'react'
import Mainlayout from '../../components/Mainlayout';
import PageHeader from '../../sharedComponents/PageHeader';
import ServiceDetails from '../../components/AboutTeamComponents/ServiceDetails';
import { images } from '../../constants';

const AboutTeam = () => {
  return (
    <Mainlayout>
      <PageHeader name1={"HOME"} name2={"ABOUT"} MainName={"THE DREAM TEAM"} backgroundImage={images.AboutPageHeaderBg} />
      <ServiceDetails/>
    </Mainlayout>
  );
}

export default AboutTeam;
