import React, { useState } from "react";
import "../../styles/MobileSidebar.css";
import { IoClose } from "react-icons/io5";
import { images } from "../../constants";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const MobileSidebar = ({ handleCloseClick }) => {
  return (
    <section className="side-bar">
      <div className="mob-side-header">
        <img width={200} src={images.Logo} alt="" />
        <IoClose
          fontSize={40}
          color="white"
          onClick={() => handleCloseClick()}
        />
      </div>
      <ul id="mob-main-navbar">
        <li className="navbar-li">
          <NavLink className="navbar-a" to={"/"}>
            HOME
          </NavLink>
        </li>
        <hr />
        <li className="navbar-li">
          <NavLink className="navbar-a" to={"/who-we-are"}>
            WHO WE ARE
          </NavLink>
        </li>
        <hr />
        <li className="navbar-li">
          <NavLink className="navbar-a" to={"/b2b-services"}>B2B SERVICES</NavLink>
        </li>
        <hr />
        <li className="navbar-li">
          <NavLink className="navbar-a" to={"/b2c-services"}>B2C SERVICES</NavLink>
        </li>
        <hr />
        <li className="navbar-li">
          <NavLink className="navbar-a" to={"/travel-and-tours"}>TRAVEL & TOURS</NavLink>
        </li>
        <hr />
        <li className="navbar-li">
          <HashLink className="navbar-a" to={"/b2c-services#jobs"}>
            INTERNSHIP
          </HashLink>
        </li>
        <hr />
        <li className="navbar-li">
          <NavLink className="navbar-a" to={"/gallery"}>
            GALLERY
          </NavLink>
        </li>
        <hr />
        <li className="navbar-li">
          <NavLink className="navbar-a" to={"/contact-us"}>
            CONTACT US
          </NavLink>
        </li>
      </ul>
    </section>
  );
};

export default MobileSidebar;
