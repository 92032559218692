import Logo from "../assets/Logo.png";
import FooterLogo from "../assets/footer-logo.png";
import WhatsAppLogo from "../assets/whatsapp.png";
import B2bPng from "../assets/b2b.png";
import B2cPng from "../assets/b2c.png";
import TravelPng from "../assets/travel.png";
import welcome1Img1 from "../assets/resources/welcome-1-img-1.jpg";
import welcome1Img2 from "../assets/resources/welcome-1-img-2.jpg";
import consultantPng from "../assets/consultant_1401918.png";
import consultingPng from "../assets/consulting.png";
import businessPng from "../assets/growth_2111118.png";
import customerReview from "../assets/customer-review.png";
import case1Img1 from "../assets/resources/cases-1-img-1.jpg";
import case1Img2 from "../assets/resources/cases-1-img-2.jpg";
import case1Img3 from "../assets/resources/cases-1-img-3.jpg";
import case1Img4 from "../assets/resources/cases-1-img-4.jpg";
import case1Img5 from "../assets/resources/cases-1-img-5.jpg";
import case1Img6 from "../assets/resources/cases-1-img-6.jpg";
import hotel from "../assets/hotel.png";
import One from "../assets/brand/1.png";
import Two from "../assets/brand/2.png";
import Three from "../assets/brand/3.png";
import Four from "../assets/brand/4.png";
import Five from "../assets/brand/5.png";
import Six from "../assets/brand/6.png";
import Seven from "../assets/brand/7.png";
import Eight from "../assets/brand/8.png";
import Nine from "../assets/brand/9.png";
import Ten from "../assets/brand/10.png";
import Eleven from "../assets/brand/11.png";
import Twelve from "../assets/brand/12.png";
import Thirteen from "../assets/brand/13.png";
import Restaurant from "../assets/restaurant.png";
import Food from "../assets/food.png";
import HR from "../assets/hr.png";
import Hospitality from "../assets/hospitality.png";
import Digital from "../assets/digital.png";
import CounterOneBg from "../assets/backgrounds/counter-one-bg.jpg";
import Partner from "../assets/partner.png";
import Student from "../assets/student.png";
import Satisfied from "../assets/satisfied.png";
import Consult from "../assets/consult.png";
import ReasonsImg1 from "../assets/resources/reasons-img-1.jpg";
import TeamOne1 from "../assets/team/team-1-1.jpg";
import TeamOne2 from "../assets/team/team-1-2.jpg";
import TeamOne3 from "../assets/team/team-1-3.jpg";
import TeamOne4 from "../assets/team/team-1-4.jpg";
import TeamOne5 from "../assets/team/team-1-5.jpg";
import TeamOne6 from "../assets/team/team-1-6.jpg";
import CtaTwoBg from "../assets/backgrounds/cta-two-bg.png";
import AboutPageHeaderBg from "../assets/backgrounds/about-page-header-bg.jpg";
import B2bPageHeader from "../assets/backgrounds/b2b-page-header-bg.jpg";
import B2cPageHeader from "../assets/backgrounds/b2c-page-header-bg.jpg";
import TravelPageHeader from "../assets/backgrounds/travel-page-header-bg.jpg";
import ServicesNeedHelpBgImg from "../assets/services/services-details-need-help-bg.jpg";
import ServicesDetailsHotelImg from "../assets/services/services-details-img-1.jpg";
import ServicesDetailRestaurantImg from "../assets/services/services-details-img-2.jpg";
import ServicesDetailImg3 from "../assets/services/services-details-img-3.jpg";
import ServicesDetailImg4 from "../assets/services/services-details-img-4.jpg";
import ServicesDetailImg5 from "../assets/services/services-details-img-5.jpg";
import ServicesDetailImg6 from "../assets/services/services-details-img-6.jpg";
import CasesDetail from "../assets/resources/cases-details-img-1.jpg";
import FaqOne from "../assets/resources/faq-one-img-1.jpg";
import ContactPageHeader from "../assets/backgrounds/contactpage-header-bg.jpg"

import g1 from "../assets/Gallery/g1.png";
import g1big from "../assets/Gallery/g1big.jpg";
import g1small from "../assets/Gallery/g1small.jpg";
import g2big from "../assets/Gallery/g2big.jpg";
import g2small from "../assets/Gallery/g2small.jpg";
import g3big from "../assets/Gallery/g3big.jpg";
import g3small from "../assets/Gallery/g3small.jpg";
import g4big from "../assets/Gallery/g4big.jpg";
import g4small from "../assets/Gallery/g4small.jpg";
import g5big from "../assets/Gallery/g5big.jpg";
import g5small from "../assets/Gallery/g5small.jpg";
import g6big from "../assets/Gallery/g6big.jpg";
import g6small from "../assets/Gallery/g6small.jpg";
import g7big from "../assets/Gallery/g7big.jpg";
import g7small from "../assets/Gallery/g7small.jpg";
import g8big from "../assets/Gallery/g8big.jpg";
import g8small from "../assets/Gallery/g8small.jpg";
import g9big from "../assets/Gallery/g9big.jpg";
import g9small from "../assets/Gallery/g9small.jpg";
import g10big from "../assets/Gallery/g10big.jpg";
import g10small from "../assets/Gallery/g10small.jpg";

const images = {
  Logo,
  FooterLogo,
  WhatsAppLogo,
  B2bPng,
  B2cPng,
  TravelPng,
  welcome1Img1,
  welcome1Img2,
  consultantPng,
  consultingPng,
  businessPng,
  customerReview,
  case1Img1,
  case1Img2,
  case1Img3,
  case1Img4,
  case1Img5,
  case1Img6,
  hotel,
  Restaurant,
  Food,
  HR,
  Hospitality,
  Digital,
  CounterOneBg,
  Partner,
  Student,
  Satisfied,
  Consult,
  ReasonsImg1,
  TeamOne1,
  TeamOne2,
  TeamOne3,
  TeamOne4,
  TeamOne5,
  TeamOne6,
  CtaTwoBg,
  AboutPageHeaderBg,
  B2bPageHeader,
  B2cPageHeader,
  ServicesNeedHelpBgImg,
  ServicesDetailsHotelImg,
  ServicesDetailRestaurantImg,
  ServicesDetailImg3,
  ServicesDetailImg4,
  ServicesDetailImg5,
  ServicesDetailImg6,
  CasesDetail,
  TravelPageHeader,
  FaqOne,
  ContactPageHeader,
};

export const gallery = {
  g1,
  g1big,
  g1small,
  g2big,
  g2small,
  g3big,
  g3small,
  g4big,
  g4small,
  g5big,
  g5small,
  g6big,
  g6small,
  g7big,
  g7small,
  g8big,
  g8small,
  g9big,
  g9small,
  g10big,
  g10small,
};

export const brand = {
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Eleven,
  Twelve,
  Thirteen,
};

export default images;
