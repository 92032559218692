import React from "react";
import Mainlayout from "../../components/Mainlayout";
import PageHeader from "../../sharedComponents/PageHeader";
import { images } from "../../constants";
import GallerySection from "../../components/GalleryComponents/GallerySection";

const JosephSchool = () => {
  return (
    <div>
      <Mainlayout>
        <PageHeader
          name1={"HOME"}
          name2={"GALLERY"}
          MainName={"CAREER MANAGEMENT COUNSELLING AT JOSEPH COLLEGE"}
          backgroundImage={images.AboutPageHeaderBg}
        />
        <GallerySection/>
      </Mainlayout>
    </div>
  );
};

export default JosephSchool;
