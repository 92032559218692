import './App.css';
import Home from './pages/home';
import WhoWeAre from './pages/WhoWeAre'
import B2bServices from "./pages/B2bServices";
import B2cServices from "./pages/B2cServices";
import TravelAndTours from "./pages/Travel&Tours";
import Gallery from "./pages/Gallery";
import ContactUs from "./pages/ContactUs";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import HotelSolution from './pages/HotelSolution';
import FoodSolution from './pages/FoodSolution';
import HrSolution from './pages/HrSolution';
import HospitalityConsulting from './pages/HospitalityConsulting';
import DigitalSolution from './pages/DigitalSolution';
import RestaurantSolution from './pages/RestaurantSolution';
import AboutTeam from './pages/AboutTeam';
import JosephSchool from './pages/CareerCounselling/JosephSchool';

function App() {
  return (
    <div>
      <Router basename="/">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/who-we-are" element={<WhoWeAre />} />
          <Route path="/b2b-services" element={<B2bServices />} />
          <Route path="/b2c-services" element={<B2cServices />} />
          <Route path="/travel-and-tours" element={<TravelAndTours />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/hotel-solutions" element={<HotelSolution />} />
          <Route path="/restaurant-solutions" element={<RestaurantSolution />} />
          <Route path="/food-solutions" element={<FoodSolution />} />
          <Route path="/hr-solutions" element={<HrSolution />} />
          <Route path="/hospitality-consulting" element={<HospitalityConsulting />} />
          <Route path="/digital-solutions" element={<DigitalSolution />} />
          <Route path="/about-team" element={<AboutTeam />} />
          <Route path="/gallery/joseph-school" element={<JosephSchool />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
