import React from 'react'

const B2cIndustries = () => {
  return (
    <div>
      <section className="industries" style={{backgroundColor:"white", marginTop:"0px"}}>
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6">
              <div className="section-title text-left">
                <h2 className="section-title__title">Our B2C Services</h2>
                <span className="section-title__tagline"></span>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="industries__top-text-box">
                <p className="industries__top-text">
                  We help students and youths interested in hospitality &amp;
                  tourism sector. We train, groom and even provide guaranteed
                  job and internship assistance worldwide.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default B2cIndustries;
