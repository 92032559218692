import React from "react";
import "../../../styles/Home/OurMission.css";
import { Link } from "react-router-dom";

const OurMission = () => {
  return (
    <section className="our-mission">
      <div className="our-mission-bg parallax"></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="our-mission__inner">
              <h2 className="our-mission__title">
                Our Mission Is To Help Grow
                <br />
                Your Hospitality Businesses
                <br />
                With Real World Ideas.
              </h2>
              <Link className="thm-btn our-mission__btn" to={"/who-we-are"}>
                DISCOVER MORE
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurMission;
