import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import "../styles/B2bSolution.css";
import { useLocation } from "react-router-dom";
import { images } from "../constants";

const B2bSolutionLayout = ({ children }) => {
  const location = useLocation();
  return (
    <section className="services-details">
      <div className="container">
        <div className="row">
          {children}
          <div className="col-xl-4 col-lg-5">
            <div className="services-details__sidebar">
              <div className="services-details__services-list-box">
                <h3 className="services-detials__categories">
                  Our B2B Services
                </h3>
                <ul className="services-details__services-list list-unstyled">
                  <li
                    className={`${
                      location.pathname === "/hotel-solutions" ? "active" : ""
                    }`}>
                    <a href="/hotel-solutions">
                      Hotel Solutions
                      <span className="icon-right-arrow">
                        <FaArrowRightLong />
                      </span>
                    </a>
                  </li>
                  <li
                    className={`${
                      location.pathname === "/restaurant-solutions"
                        ? "active"
                        : ""
                    }`}>
                    <a href="/restaurant-solutions">
                      Restaurant Solutions
                      <span className="icon-right-arrow">
                        <FaArrowRightLong />
                      </span>
                    </a>
                  </li>
                  <li
                    className={`${
                      location.pathname === "/food-solutions" ? "active" : ""
                    }`}>
                    <a href="/food-solutions">
                      Food Solutions
                      <span className="icon-right-arrow">
                        <FaArrowRightLong />
                      </span>
                    </a>
                  </li>
                  <li
                    className={`${
                      location.pathname === "/hr-solutions" ? "active" : ""
                    }`}>
                    <a href="/hr-solutions">
                      HR Solutions
                      <span className="icon-right-arrow">
                        <FaArrowRightLong />
                      </span>
                    </a>
                  </li>
                  <li
                    className={`${
                      location.pathname === "/hospitality-consulting"
                        ? "active"
                        : ""
                    }`}>
                    <a href="/hospitality-consulting">
                      Hospitality Consulting
                      <span className="icon-right-arrow">
                        <FaArrowRightLong />
                      </span>
                    </a>
                  </li>
                  <li
                    className={`${
                      location.pathname === "/digital-solutions" ? "active" : ""
                    }`}>
                    <a href="/digital-solutions">
                      Digital Solutions
                      <span className="icon-right-arrow">
                        <FaArrowRightLong />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="services-details__help-box">
                <div
                  className="services-details__help-box-bg"
                  style={{
                    backgroundImage: `url(${images.ServicesNeedHelpBgImg})`,
                  }}></div>
                <div class="services-details__help-box-bg-overly"></div>
                <h3 class="services-details__help-box-title">Need Help?</h3>
                <p className="services-details__help-box-text">
                  Prefer speaking directly with us rather than finding
                  information on this site? call us and we will connect you with
                  a team member who can help.
                </p>
                <a href="tel:+92-666-888-0000" class="services-details__phone">
                  +977-9843313786
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default B2bSolutionLayout;
