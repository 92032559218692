import React from "react";
import Mainlayout from "../../components/Mainlayout";
import MainSlider from "../../components/HomeComponents/MainSlider/MainSlider";
import OurServices from "../../components/HomeComponents/OurServices/OurServices";
import Welcome from "../../components/HomeComponents/Welcome/Welcome";
import TwoBoxes from "../../components/HomeComponents/TwoBoxes/TwoBoxes";
import Industries from "../../components/HomeComponents/Industries/Industries";
import OurMission from "../../components/HomeComponents/OurMission/OurMission";
import CasesOne from "../../components/HomeComponents/CasesOne/CasesOne";
import CounterOne from "../../components/HomeComponents/CounterOne/CounterOne";
import Reasons from "../../components/HomeComponents/Reasons/Reasons";

const Home = () => {
  return (
    <div>
      <Mainlayout>
        <MainSlider />
        <OurServices />
        <Welcome />
        <TwoBoxes />
        <Industries />
        <OurMission />
        <CasesOne />
        <CounterOne />
        <Reasons/>
      </Mainlayout>
    </div>
  );
};

export default Home;
