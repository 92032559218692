import React from "react";
import Mainlayout from "../../components/Mainlayout";
import PageHeader from "../../sharedComponents/PageHeader";
import { images } from "../../constants";
import ServicesOne from "../../components/GalleryComponents/ServicesOne";

const Gallery = () => {
  return (
    <div>
      <Mainlayout>
        <PageHeader
          name1={"HOME"}
          name2={"GALLERY"}
          MainName={"OUR GALLERY"}
          backgroundImage={images.AboutPageHeaderBg}
        />
        <ServicesOne/>
      </Mainlayout>
    </div>
  );
};

export default Gallery;
