import React from 'react'
import Mainlayout from '../../components/Mainlayout'
import CasesOne from '../../components/HomeComponents/CasesOne/CasesOne'
import PageHeader from '../../sharedComponents/PageHeader'
import { images } from '../../constants'

const B2bServices = () => {
  return (
    <div>
      <Mainlayout>
        <PageHeader
          name1={"HOME"}
          name2={"B2B"}
          MainName={"HOSPITALITY MANAGEMENT SERVICES"}
          backgroundImage={images.B2bPageHeader}
        />
        <CasesOne />
      </Mainlayout>
    </div>
  );
}

export default B2bServices
