import React from "react";

const GoogleMap = () => {
  return (
    <div>
      <section
        class="google-map"
        style={{ position: "relative", display: "block" }}>
        <iframe
          style={{
            position: "relative",
            display: "block",
            border: "none",
            height: "555px",
            width: "100%",
          }}
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14129.788665876054!2d85.3344766!3d27.7034766!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3786040b012e442d!2sDream%20Sailor%20Hospitality%20and%20Consulting!5e0!3m2!1sen!2snp!4v1637752882253!5m2!1sen!2snp"
          class="google-map__one"
          allowfullscreen=""></iframe>
      </section>
    </div>
  );
};

export default GoogleMap;
