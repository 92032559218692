import React from "react";
import Mainlayout from "../../components/Mainlayout";
import PageHeader from "../../sharedComponents/PageHeader";
import { images } from "../../constants";
import B2bSolutionLayout from "../../sharedComponents/B2bSolutionLayout";
import { IoCheckmark } from "react-icons/io5";
import "../../styles/Services/ServicesSolution.css";

const HrSolution = () => {
  return (
    <div>
      <Mainlayout>
        <PageHeader
          name1={"HOME"}
          name2={"B2B"}
          MainName={"HR SOLUTIONS"}
          backgroundImage={images.B2bPageHeader}
        />
        <B2bSolutionLayout>
          <div className="col-xl-8 col-lg-7">
            <div className="services-details__left">
              <div className="services-details__img">
                <img src={images.ServicesDetailImg4} alt="" />
              </div>
              <div className="services-details__top-content">
                <h2 className="services-details__top-title">HR SOLUTIONS</h2>
                <p className="services-details__top-text">
                  We assist on recruiting, hiring, deploying and managing of any
                  hospitality sector employees.
                </p>
              </div>
              <div className="services-details__planning">
                <div className="services-details__planning-content">
                  <h2 className="services-details__planning-title">
                    WHAT WE ASSIST
                  </h2>
                  <p className="services-details__planning-text">
                    We assist on following services to any hospitality sectors
                    but not limited to :
                  </p>
                  <ul className="list-unstyled services-details__planning-list">
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Staffing.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Development.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Compensation.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Safety & Health.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Employee & Labor Relations</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </B2bSolutionLayout>
      </Mainlayout>
    </div>
  );
};

export default HrSolution;
