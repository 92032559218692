import React from 'react'
import Mainlayout from '../../components/Mainlayout';
import PageHeader from '../../sharedComponents/PageHeader';
import { images } from '../../constants';
import FaqOne from '../../components/Travel&Tours/FaqOne';

const TravelAndTours = () => {
  return (
    <div>
      <Mainlayout>
        <PageHeader
          name1={"HOME"}
          name2={"TRAVEL & TOURS"}
          MainName={"TRAVEL & TOURS"}
          backgroundImage={images.TravelPageHeader}
        />
        <FaqOne/>
      </Mainlayout>
    </div>
  );
}

export default TravelAndTours;
