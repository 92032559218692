import React from "react";
import "../../styles/Location.css";

const Location = () => {
  return (
    <div>
      <section className="locations">
        <div className="container">
          <div className="location__inner">
            <div className="section-title text-center">
              <h2 className="section-title__title">Our Nepal Office</h2>
              <span className="section-title__tagline">
                Come and visit us for Hospitality &amp; Travel Solutions
              </span>
            </div>
            <div className="row text-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="locations__single">
                  <h3 className="locations__title">Maitidevi</h3>
                  <p className="locations__text">Kathmandu, Nepal</p>
                  <h4 className="locations__mail-phone-box">
                    <a
                      href="mailto:info@dreamsailorhospitality.com.np"
                      className="locations__mail">
                      info@dreamsailorhospitality.com.np
                    </a>
                    <a href="tel:+977-9843313786" className="locations__phone">
                      +977-9843313786{" "}
                    </a>{" "}
                    <a href="tel:+977-1-5911044">+977-1-5911044</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Location;
