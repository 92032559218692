import React from "react";
import "../../../styles/Home/Reasons.css";
import { IoCheckmark } from "react-icons/io5";
import images from "../../../constants/images";

const Reasons = () => {
  return (
    <section className="reasons">
      <div className="container">
        <div className="reasons-bg"></div>
        <div className="row">
          <div className="col-xl-5 col-lg-6">
            <div className="reasons__left">
              <h2 className="reasons__title">REASONS FOR CHOOSING US</h2>
              <ul className="list-unstyled reasons__list-box">
                <li>
                  <div className="reasons__icon">
                    <span className="icon-tick">
                      <IoCheckmark />
                    </span>
                  </div>
                  <div className="reasons__content">
                    <h4 className="reasons__content-title">EXPERIENCED TEAM</h4>
                    <p className="reasons__content-text">
                      We have 2 decades of combined experience in hospitality
                      management and consulting.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="reasons__icon">
                    <span className="icon-tick">
                      <IoCheckmark />
                    </span>
                  </div>
                  <div className="reasons__content">
                    <h4 className="reasons__content-title">EXPERT ADVICE</h4>
                    <p className="reasons__content-text">
                      Our consulting and management process is based upon our
                      real world experiences.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="reasons__icon">
                    <span className="icon-tick">
                      <IoCheckmark />
                    </span>
                  </div>
                  <div className="reasons__content">
                    <h4 className="reasons__content-title">
                      WORLDWIDE PARTNERS
                    </h4>
                    <p className="reasons__content-text">
                      We have partners worldwide fulfilling needs of
                      international hospitality customers.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6">
            <div className="reasons__img-box">
              <div className="reasons__img">
                <img src={images.ReasonsImg1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reasons;
