import React from "react";
import "../../../styles/Home/TwoBoxes.css";

const TwoBoxes = () => {
  return (
    <section className="two-boxes">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="two-boxes__single">
              <div className="two-boxes__single-content">
                <div className="two-boxes__count">
                  <span></span>
                </div>
                <div className="two-boxes__content">
                  <h3 className="two-boxes__title">
                    Get Free Career Consulting
                  </h3>
                  <p className="two-boxes__text">
                    Youths interested in hospitality & tourism industry can get
                    a free career consulting at Dream Sailor.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="two-boxes__single">
              <div className="two-boxes__single-content">
                <div className="two-boxes__count">
                  <span></span>
                </div>
                <div className="two-boxes__content">
                  <h3 className="two-boxes__title">JOB ASSISTANCE</h3>
                  <p className="two-boxes__text">
                    100% Job and Internship Assistance in hospitality sector
                    worldwide after training with us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TwoBoxes;
