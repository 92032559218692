import React from 'react'
import Mainlayout from '../../components/Mainlayout';
import PageHeader from '../../sharedComponents/PageHeader';
import { images } from '../../constants';
import B2cIndustries from '../../components/B2cServicesComponents/B2cIndustries';
import B2cCasestudy from '../../components/B2cServicesComponents/B2cCasestudy';

const B2cServices = () => {
  return (
    <div>
      <Mainlayout>
        <PageHeader
          name1={"HOME"}
          name2={"B2C"}
          MainName={"CONSULTING, COUNSELLING & TRAINING"}
          backgroundImage={images.B2cPageHeader}
        />
        <B2cIndustries />
        <B2cCasestudy/>
      </Mainlayout>
    </div>
  );
}

export default B2cServices
