import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import "../styles/BrandTree.css"
import { brand } from '../constants/images';
import { Autoplay } from "swiper/modules";
import "swiper/css";

const BrandTree = () => {
  return (
    <section className="brand-tree">
      <div className="container">
        <Swiper
          spaceBetween={0}
          slidesPerView={5}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}>
          <SwiperSlide>
            <img width={"30%"} src={brand.One} alt="Brand" />
          </SwiperSlide>
          <SwiperSlide>
            <img width={"30%"} src={brand.Two} alt="Brand" />
          </SwiperSlide>
          <SwiperSlide>
            <img width={"30%"} src={brand.Three} alt="Brand" />
          </SwiperSlide>
          <SwiperSlide>
            <img width={"30%"} src={brand.Four} alt="Brand" />
          </SwiperSlide>
          <SwiperSlide>
            <img width={"30%"} src={brand.Five} alt="Brand" />
          </SwiperSlide>
          <SwiperSlide>
            <img width={"30%"} src={brand.Six} alt="Brand" />
          </SwiperSlide>
          <SwiperSlide>
            <img width={"30%"} src={brand.Seven} alt="Brand" />
          </SwiperSlide>
          <SwiperSlide>
            <img width={"30%"} src={brand.Eight} alt="Brand" />
          </SwiperSlide>
          <SwiperSlide>
            <img width={"30%"} src={brand.Nine} alt="Brand" />
          </SwiperSlide>
          <SwiperSlide>
            <img width={"30%"} src={brand.Ten} alt="Brand" />
          </SwiperSlide>
          <SwiperSlide>
            <img width={"30%"} src={brand.Eleven} alt="Brand" />
          </SwiperSlide>
          <SwiperSlide>
            <img width={"30%"} src={brand.Twelve} alt="Brand" />
          </SwiperSlide>
          <SwiperSlide>
            <img width={"30%"} src={brand.Thirteen} alt="Brand" />
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}

export default BrandTree
