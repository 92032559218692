import React from "react";
import images from "../../../constants/images";
import { Link } from "react-router-dom";
import "../../../styles/Home/OursServices.css"
import { Zoom } from "react-reveal";

const OurServices = () => {
  return (
    <section className="real-world">
        <div className="real-world-shape"></div>
      <div className="container">
        <div className="section-title text-center">
          <h2 className="section-title__title">OUR SERVICES SECTORS</h2>
          <span className="section-title__tagline">
            The best hospitality management and consulting you can count on!
          </span>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-4">
            <div className="real-world__single">
              <h2 className="real-world__title">
                <Link to={"/b2b-services"}>
                  BUSINESS TO <br /> BUSINESS
                </Link>
              </h2>
              <Link className="real-world__btn" to={"/b2b-services"}>
                READ MORE
              </Link>
              <div className="real-world__icon-box">
                <span>
                  <img src={images.B2bPng} alt="B2b" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div className="real-world__single">
              <h2 className="real-world__title">
                <Link to={"/b2c-services"}>
                  BUSINESS TO <br /> CONSUMERS
                </Link>
              </h2>
              <Link className="real-world__btn" to={"/b2c-services"}>
                READ MORE
              </Link>
              <div className="real-world__icon-box">
                <span>
                  <img src={images.TravelPng} alt="B2c" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div className="real-world__single">
              <h2 className="real-world__title">
                <Link to={"/travel-and-tours"}>
                  TRAVEL & <br /> TOURS
                </Link>
              </h2>
              <Link className="real-world__btn" to={"/travel-and-tours"}>
                READ MORE
              </Link>
              <div className="real-world__icon-box">
                <span>
                  <img src={images.TravelPng} alt="travel" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServices;
