import React from "react";
import "../styles/Footer.css";
import { images } from "../constants";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FaPhoneSquareAlt,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Zoom from "react-reveal/Zoom";

const Footer = () => {
  return (
    <footer>
      <div className="footer font-jost">
        <div className="footer-backgroundImage"></div>
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="footer-top-left">
                  <div className="mb-5">
                    <Zoom>
                      <img src={images.FooterLogo} alt="footer-logo" />
                    </Zoom>
                  </div>
                  <Zoom>
                    <p className="footer-p">
                      Dream Sailor Hospitality & Consulting : Helping you Sail
                      through Your Dreams of Running a Successful Hospitality
                      Business.
                    </p>
                  </Zoom>
                  <ul className="list-unstyled contact-list mt-4 pt-4">
                    <Zoom>
                      <li className="footer-li">
                        <div className="icon">
                          <FaPhoneSquareAlt />
                        </div>
                        <div className="text">
                          <p className="fw-bold">
                            <a
                              class="text-decoration-none text-secondary "
                              href="tel:+977-9843313786">
                              +977-9843313786
                            </a>
                            ,
                            <a
                              class="text-decoration-none text-secondary"
                              href="tel:+977-1-5911044">
                              +977-1-5911044
                            </a>
                          </p>
                        </div>
                      </li>
                      <li className="footer-li">
                        <div className="icon">
                          <IoMdMail />
                        </div>
                        <div className="text">
                          <p className="fw-bold">
                            <a
                              class="text-decoration-none text-secondary"
                              href="mailto:info@dreamsailorhospitality.com.np">
                              info@dreamsailorhospitality.com.np
                            </a>
                          </p>
                        </div>
                      </li>
                      <li className="footer-li">
                        <div className="icon">
                          <FaLocationDot />
                        </div>
                        <div className="text-secondary font-weight-bold">
                          <p className="fw-bold">Maitidevi, Kathmandu, Nepal</p>
                        </div>
                      </li>
                    </Zoom>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <Zoom>
                  <h3 className="text-white fs-5 fw-bold mb-5">EXPLORE</h3>
                  <div className="d-flex gap-5">
                    <ul className="list-unstyled">
                      <li className="mb-3">
                        <Link
                          className="text-decoration-none text-secondary"
                          to={"/who-we-are"}>
                          About us
                        </Link>
                      </li>
                      <li className="mb-3">
                        <Link
                          className="text-decoration-none text-secondary"
                          to={"/who-we-are#ourteam"}>
                          Meet Our Team
                        </Link>
                      </li>
                      <li className="mb-3">
                        <Link
                          className="text-decoration-none text-secondary"
                          to={"/contact-us"}>
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                    <ul className="list-unstyled">
                      <li className="mb-3">
                        <Link
                          className="text-decoration-none text-secondary"
                          to={"/b2b-services"}>
                          B2B Services
                        </Link>
                      </li>
                      <li className="mb-3">
                        <Link
                          className="text-decoration-none text-secondary"
                          to={"/b2c-services"}>
                          B2C Sevices
                        </Link>
                      </li>
                      <li className="mb-3">
                        <Link
                          className="text-decoration-none text-secondary"
                          to={"/travel-and-tours"}>
                          Travel & Tours
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Zoom>
              </div>
              <Zoom>
                <div className="col-xl-4 col-lg-6 col-md-6">
                  <h3 className="text-white fs-5 fw-bold mb-5">WHATS APP</h3>
                  <img src={images.WhatsAppLogo} alt="whatsapp" />
                </div>
              </Zoom>
            </div>
          </div>
          <div className="footer-bottom text-secondary d-flex border-top border-dark mt-3 pt-4 pb-4 justify-content-between">
            <p className="fw-bold fs-6 ">
              © 2024 Dream Sailor. Managed by Raghav
            </p>
            <div className="d-flex gap-4">
              <a
                href="https://facebook.com/dreamsailorhospitality"
                target="_blank"
                className="text-secondary">
                <FaFacebook />
              </a>
              <a
                href="https://instagram.com/dreamsailorhospitality"
                target="_blank"
                className="text-secondary">
                <FaInstagram />
              </a>
              <a
                href="https://linkedin.com/company/dream-sailor-hospitality"
                target="_blank"
                className="text-secondary">
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
