import React from "react";
import "../../styles/MessageBox.css";
import {FaFacebook, FaInstagram, FaLinkedin, FaRegComment } from "react-icons/fa";
import { FaRegUserCircle } from "react-icons/fa";
import { IoMailOutline } from "react-icons/io5";
import { FaMobileScreenButton } from "react-icons/fa6";


const MessageBox = () => {
  return (
    <div>
      <section className="message-box">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="message-box__left">
                <div className="section-title text-left">
                  <h2 className="section-title__title">get in touch with us</h2>
                  <span className="section-title__tagline">
                    Call us, Email us or Write "a" message.
                  </span>
                </div>
                <div className="message-box__social">
                  <a
                    href="https://facebook.com/dreamsailorhospitality"
                    target="_blank"
                    className="clr-fb">
                    <FaFacebook />
                  </a>

                  <a
                    href="https://instagram.com/dreamsailorhospitality"
                    target="_blank"
                    className="clr-ins">
                    <FaInstagram />
                  </a>
                  <a
                    href="https://linkedin.com/company/dream-sailor-hospitality"
                    target="_blank"
                    className="clr-dri">
                    <FaLinkedin />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="message-box__right">
                <form
                  id="results"
                  action="#results"
                  method="post"
                  className="comment-one__form contact-form-validated"
                  novalidate="novalidate">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="comment-form__input-box">
                        <input
                          type="text"
                          placeholder="Your name"
                          name="Name"
                        />
                        <div className="comment-form__icon">
                          <FaRegUserCircle />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="comment-form__input-box">
                        <input
                          type="text"
                          placeholder="Phone Number"
                          name="Phone"
                        />
                        <div className="comment-form__icon">
                          <FaMobileScreenButton />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="comment-form__input-box">
                        <input
                          type="email"
                          placeholder="Email Address"
                          name="Email"
                        />
                        <div className="comment-form__icon">
                          <IoMailOutline />
                          <i className="far fa-envelope"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-12">
                      <div className="comment-form__input-box">
                        <textarea
                          name="Message"
                          placeholder="Write Message"></textarea>
                        <div className="comment-form__icon contact-expert__icon-comment">
                          <FaRegComment />
                        </div>
                      </div>
                      <div>
                        <div
                          className="grecaptcha-badge"
                          data-style="bottomright"
                          style={{
                            width: "256px",
                            height: "60px",
                            display: "block",
                            transition: "right 0.3s ease 0s",
                            position: "fixed",
                            bottom: "14px",
                            right: "-186px",
                            boxShadow: "gray 0px 0px 5px",
                            borderRadius: "2px",
                            overflow: "hidden",
                          }}>
                          <div className="grecaptcha-logo">
                            <iframe
                              title="reCAPTCHA"
                              width="256"
                              height="60"
                              role="presentation"
                              name="a-co6012fw8zz"
                              frameborder="0"
                              scrolling="no"
                              sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                              src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LdZlWAfAAAAAMDPStSwBEQQ9Bd6SH_DlVbLyffQ&amp;co=aHR0cHM6Ly93d3cuZHJlYW1zYWlsb3Job3NwaXRhbGl0eS5jb20ubnA6NDQz&amp;hl=en&amp;v=vj7hFxe2iNgbe-u95xTozOXW&amp;size=invisible&amp;sa=submit&amp;cb=uv5s43dsjc7y"></iframe>
                          </div>
                          <div className="grecaptcha-error"></div>
                          <textarea
                            id="g-recaptcha-response"
                            name="g-recaptcha-response"
                            className="g-recaptcha-response"
                            style={{
                              width: "250px",
                              height: "40px",
                              border: "1px solid rgb(193, 193, 193)",
                              margin: "10px 25px",
                              padding: "0px",
                              resize: "none",
                              display: "none",
                            }}></textarea>
                        </div>
                        <iframe style={{ display: "none" }}></iframe>
                      </div>
                      <button
                        type="submit"
                        className="g-recaptcha thm-btn comment-form__btn"
                        data-sitekey="6LdZlWAfAAAAAMDPStSwBEQQ9Bd6SH_DlVbLyffQ"
                        data-callback="onSubmit"
                        data-action="submit">
                        Send
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <p style={{ color: "green" }}></p>
                      <p style={{ color: "red" }}></p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MessageBox;
