import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/DropDown.css";

const DropDown = ({ isVisible }) => {
  const navigate = useNavigate();

  const handleItemClick = (route) => {
    navigate(route);
  };

  

  return (
    <ul className={`service-submenu  ${isVisible ? "visible" : ""}`}>
      <li onClick={() => handleItemClick("/who-we-are")}>
        <a>ABOUT US</a>
      </li>
      {/* <li onClick={() => handleItemClick("/who-we-are#our-team")}>
        <Link>OUR TEAM</Link>
      </li> */}
      <li onClick={() => handleItemClick("/who-we-are")}>
        <a href="#our-team">OUR TEAM</a>
      </li>
    </ul>
  );
};

export default DropDown;
