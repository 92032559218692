import React from "react";
import { gallery } from "../../constants/images";
import LightGallery from "lightgallery/react/Lightgallery.es5";
import "lightgallery/css/lightgallery.css";

const GallerySection = () => {
  return (
    <div>
      <section className="services-one services-page">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="section-title__title">Our Gallery</h2>
            <span className="section-title__tagline">
              Career Management Counselling at Joseph College, Mahankal on
              29/11/2078.
            </span>
          </div>
          <div className="row lg-ignore">
            <div
              className="col-xl-4 col-lg-4 col-md-4 ml-3 mr-3"
              style={{ paddingBottom: "30px" }}>
              <LightGallery speed={500}>
                <a className="example-image-link" href={gallery.g1big}>
                  <img
                    className="example-image img-fluid"
                    src={gallery.g1small}
                    alt=""
                  />
                </a>
              </LightGallery>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-4"
              style={{ paddingBottom: "30px" }}>
              <LightGallery speed={500}>
                <a className="example-image-link" href={gallery.g2big}>
                  <img
                    className="example-image img-fluid"
                    src={gallery.g2small}
                    alt=""
                  />
                </a>
              </LightGallery>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-4"
              style={{ paddingBottom: "30px" }}>
              <LightGallery speed={500}>
                <a
                  className="example-image-link"
                  href={gallery.g3big}
                  data-lightbox="example-set"
                  data-title="Click the right half of the image to move forward.">
                  <img
                    className="example-image img-fluid"
                    src={gallery.g3small}
                    alt=""
                  />
                </a>
              </LightGallery>
            </div>

            <div
              className="col-xl-4 col-lg-4 col-md-4 ml-3 mr-3"
              style={{ paddingBottom: "30px" }}>
              <LightGallery speed={500}>
                <a
                  className="example-image-link"
                  href={gallery.g4big}
                  data-lightbox="example-set"
                  data-title="Click the right half of the image to move forward.">
                  <img
                    className="example-image img-fluid"
                    src={gallery.g4small}
                    alt=""
                  />
                </a>
              </LightGallery>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-4"
              style={{ paddingBottom: "30px" }}>
              <LightGallery speed={500}>
                <a
                  className="example-image-link"
                  href={gallery.g5big}
                  data-lightbox="example-set"
                  data-title="Click the right half of the image to move forward.">
                  <img
                    className="example-image img-fluid"
                    src={gallery.g5small}
                    alt=""
                  />
                </a>
              </LightGallery>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-4"
              style={{ paddingBottom: "30px" }}>
              <LightGallery speed={500}>
                <a
                  className="example-image-link"
                  href={gallery.g6big}
                  data-lightbox="example-set"
                  data-title="Click the right half of the image to move forward.">
                  <img
                    className="example-image img-fluid"
                    src={gallery.g6small}
                    alt=""
                  />
                </a>
              </LightGallery>
            </div>

            <div
              className="col-xl-4 col-lg-4 col-md-4 ml-3 mr-3"
              style={{ paddingBottom: "30px" }}>
              <LightGallery speed={500}>
                <a
                  className="example-image-link"
                  href={gallery.g7big}
                  data-lightbox="example-set"
                  data-title="Click the right half of the image to move forward.">
                  <img
                    className="example-image img-fluid"
                    src={gallery.g7small}
                    alt=""
                  />
                </a>
              </LightGallery>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-4"
              style={{ paddingBottom: "30px" }}>
              <LightGallery speed={500}>
                <a
                  className="example-image-link"
                  href={gallery.g8big}
                  data-lightbox="example-set"
                  data-title="Click the right half of the image to move forward.">
                  <img
                    className="example-image img-fluid"
                    src={gallery.g8small}
                    alt=""
                  />
                </a>
              </LightGallery>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-4"
              style={{ paddingBottom: "30px" }}>
              <LightGallery speed={500}>
                <a
                  className="example-image-link"
                  href={gallery.g9big}
                  data-lightbox="example-set"
                  data-title="Click the right half of the image to move forward.">
                  <img
                    className="example-image img-fluid"
                    src={gallery.g9small}
                    alt=""
                  />
                </a>
              </LightGallery>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-4"
              style={{ paddingBottom: "30px" }}>
              <LightGallery speed={500}>
                <a
                  className="example-image-link"
                  href={gallery.g10big}
                  data-lightbox="example-set"
                  data-title="Click the right half of the image to move forward.">
                  <img
                    className="example-image img-fluid"
                    src={gallery.g10small}
                    alt=""
                  />
                </a>
              </LightGallery>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GallerySection;
