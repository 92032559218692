import React from "react";
import { Link, useNavigate } from "react-router-dom";
import DropDownB2cServices from "./DropDownB2cServices";

const DropDownServices = ({ isVisible, setState, state }) => {
  const [isSubmenuHover, setIsSubmenuHover] = React.useState(false);
  const navigate = useNavigate();

  const handleItemClick = (route) => {
    navigate(route);
  };
  return (
    <ul className={`service-submenu  ${isVisible ? "visible" : ""}`}>
      <li onClick={() => handleItemClick("/b2b-services")}>
        <Link>B2B SERVICES</Link>
      </li>
      <li
        onClick={() =>
          isSubmenuHover == false ? handleItemClick("/b2c-services") : ""
        }
        onMouseEnter={() => setState(true)}
        onMouseLeave={() => setState(false)}>
        <Link>B2C SERVICES</Link>
        {state && (
          <DropDownB2cServices
            isVisible={isVisible}
            setIsSubmenuHover={setIsSubmenuHover}
          />
        )}
      </li>
      <li onClick={() => handleItemClick("/travel-and-tours")}>
        <Link>TRAVEL & TOURS</Link>
      </li>
    </ul>
  );
};

export default DropDownServices;
