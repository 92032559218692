import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "../../../styles/Home/MainSlider.css";
import { Link } from "react-router-dom";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import { Zoom } from "react-reveal";

const MainSlider = () => {
  const [activeSlide, setActiveSlide] = React.useState(0);

  const keySuffix = activeSlide;

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper.activeIndex);
  };

  return (
    <section className="main-slider">
      <Swiper
        navigation={true}
        modules={[Navigation]}
        onSlideChange={handleSlideChange}>
        <SwiperSlide key={keySuffix === 0 ? "slide-0" : ""}>
          <div className="main-slider-bg-one-img"></div>
          <div className={`main-slider-shape-1`}></div>
          <div className="main-slider-shape-2"></div>
          <div className="main-slider-shape-3"></div>
          <div className="main-slider-shape-4"></div>
          <div className="main-slider-shape-5"></div>
          <div className="main-slider-one-elements">
            <div className="main-slider-one-left container">
              <div className="row">
                <div className="col-lg-8">
                  <Zoom when={activeSlide === 0}>
                    <p>WELCOME TO DREAM SAILOR</p>
                  </Zoom>
                  <Zoom when={activeSlide === 0}>
                    <h2>
                      MANAGING
                      <br />
                      HOSPITALITY
                      <br />
                      INDUSTRY
                    </h2>
                  </Zoom>
                  <Zoom when={activeSlide === 0}>
                    <Link className="thm-btn">Discover More</Link>
                  </Zoom>
                </div>
              </div>
            </div>
            <div className="main-slider-one-right"></div>
          </div>
        </SwiperSlide>
        <SwiperSlide key={keySuffix === 1 ? "slide-1" : ""}>
          <div className="main-slider-bg-two-img"></div>
          <div className="main-slider-shape-1"></div>
          <div className="main-slider-shape-2"></div>
          <div className="main-slider-shape-3"></div>
          <div className="main-slider-shape-4"></div>
          <div className="main-slider-shape-5"></div>
          <div className="main-slider-two-elements">
            <div className="main-slider-two-left container">
              <div className="row">
                <div className="col-lg-8">
                  <Zoom when={activeSlide === 1}>
                    <p>Travel & Tours</p>
                  </Zoom>
                  <Zoom when={activeSlide === 1}>
                    <h2>
                      HOSPITALITY
                      <br />
                      COUNSELLING &
                      <br />
                      CONSULTING
                    </h2>
                  </Zoom>
                  <Zoom when={activeSlide === 1}>
                    <Link className="thm-btn">Discover More</Link>
                  </Zoom>
                </div>
              </div>
            </div>
            <div className="main-slider-two-right"></div>
          </div>
        </SwiperSlide>
        <SwiperSlide key={`slide-${keySuffix === 2}`}>
          {" "}
          <div className="main-slider-bg-three-img"></div>
          <div className="main-slider-shape-1"></div>
          <div className="main-slider-shape-2"></div>
          <div className="main-slider-shape-3"></div>
          <div className="main-slider-shape-4"></div>
          <div className="main-slider-shape-5"></div>
          <div className="main-slider-two-elements">
            <div className="main-slider-two-left container">
              <div className="row">
                <div className="col-lg-8">
                  <Zoom when={activeSlide === 2}>
                    <p>EDUCATION & VISA</p>
                  </Zoom>
                  <Zoom when={activeSlide === 2}>
                    <h2>
                      MANAGING
                      <br />
                      WORLDWIDE
                      <br />
                      TRAVEL
                    </h2>
                  </Zoom>
                  <Zoom when={activeSlide === 2}>
                    <Link className="thm-btn">Discover More</Link>
                  </Zoom>
                </div>
              </div>
            </div>
            <div className="main-slider-two-right"></div>
          </div>
        </SwiperSlide>
        <SwiperSlide key={`slide-${keySuffix + 3}`}>
          {" "}
          <div className="main-slider-bg-four-img"></div>
          <div className="main-slider-shape-1"></div>
          <div className="main-slider-shape-2"></div>
          <div className="main-slider-shape-3"></div>
          <div className="main-slider-shape-4"></div>
          <div className="main-slider-shape-5"></div>
          <div className="main-slider-two-elements">
            <div className="main-slider-two-left container">
              <div className="row">
                <div className="col-lg-8">
                  <Zoom when={activeSlide === 3}>
                    <p>EDUCATION & VISA</p>
                  </Zoom>
                  <Zoom when={activeSlide === 3}>
                    <h2>
                      ABROAD
                      <br />
                      COUNCELING
                      <br />
                      <br />
                    </h2>
                  </Zoom>
                  <Zoom when={activeSlide === 3}>
                    <Link className="thm-btn">Discover More</Link>
                  </Zoom>
                </div>
              </div>
            </div>
            <div className="main-slider-two-right"></div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default MainSlider;
