import React from "react";
import { Link, useNavigate } from "react-router-dom";

const DropDownB2cServices = ({ isVisible,setIsSubmenuHover}) => {
  const navigate = useNavigate();

  const handleItemClick = (route) => {
    navigate(route);
  };
  return (
    <ul
      className={`service-submenu  ${isVisible ? "visible" : ""}`}
      style={{ marginLeft: "9em" }}
      onMouseEnter={() => setIsSubmenuHover(true)}>
      <li onClick={() => handleItemClick("/b2c-services#vocational")}>
        {/* <Link>VOCATIONAL EDUCATION</Link> */}
        <a href="#vocational">VOCATIONAL EDUCATION</a>
      </li>
      <li onClick={() => handleItemClick("/b2c-services#abroad")}>
        {/* <Link>ABROAD CONSULTATION</Link> */}
        <a href="#abroad">ABROAD CONSULTATION</a>
      </li>
      <li onClick={() => handleItemClick("/b2c-services#personal")}>
        {/* <Link>PERSONAL GROOMING</Link> */}
        <a href="#personal">PERSONAL GROOMING</a>
      </li>
      <li onClick={() => handleItemClick("/b2c-services#jobs")}>
        {/* <Link>JOBS & INTERNSHIP</Link> */}
        <a href="#jobs">JOBS & INTERNSHIP</a>
      </li>
    </ul>
  );
};

export default DropDownB2cServices;
