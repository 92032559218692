import React from "react";
import { gallery } from "../../constants/images";
import "../../styles/Gallery.css";

const ServicesOne = () => {
  return (
    <div>
      <section className="services-one services-page">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="section-title__title">Our Gallery</h2>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 ">
              <div className="services-one__single">
                <div className="services-one__img">
                  <img src={gallery.g1} alt="" />
                </div>
                <div className="services-one__content">
                  <p className="services-one__text">
                    Career Management Counselling at Joseph College, Mahankal{" "}
                  </p>
                  <p>
                    <b>Date: 29/11/2078</b>
                  </p>
                  <a
                    href="/gallery/joseph-school"
                    className="services-one__btn">
                    View Gallery
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesOne;
