import React from "react";
import { images } from "../../constants";
import "../../styles/Services/FaqOne.css";
import { IoCheckmark } from "react-icons/io5";

const FaqOne = () => {
  return (
    <section className="faq-one">
      <div className="container">
        <div className="section-title text-center">
          <h2 className="section-title__title">Travel &amp; Tours</h2>
          <span className="section-title__tagline">
            Your Reliable Worldwide Travel &amp; Tours Partner
          </span>
        </div>
        <div className="row">
          <div className="col-xl-6">
            <div className="faq-one__left">
              <div
                className="accrodion-grp faq-one-accrodion"
                data-grp-name="faq-one-accrodion">
                <div className="accrodion active">
                  <div className="accrodion-title">
                    <h4>
                      <span>.</span> Ticketing
                    </h4>
                  </div>
                  <div className="accrodion-content">
                    <div className="inner">
                      <p>
                        Cheap &amp; Affordable National &amp; International
                        Airlines Ticket.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accrodion active">
                  <div className="accrodion-title">
                    <h4>
                      <span>.</span> Tour Packages
                    </h4>
                  </div>
                  <div className="accrodion-content">
                    <div className="inner">
                      <p>
                        Tour Packages to Selected Countries with tour guides and
                        transportation facility.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accrodion active">
                  <div className="accrodion-title">
                    <h4>
                      <span>.</span> Accomodation{" "}
                    </h4>
                  </div>
                  <div className="accrodion-content">
                    <div className="inner">
                      <p>
                        Accomodation &amp; Hotel Booking to Selected Countries.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="faq-one__right">
              <div className="faq-one__img">
                <img src={images.FaqOne} alt="" />
              </div>
              <h3 style={{ paddingTop: "20px" }}>
                Countries We Currently Provide Services To
              </h3>
              <div className="faq-one__bottom " style={{ textAlign: "center" }}>
                <div className="faq-one__list-box">
                  <ul className="list-unstyled faq-one__list">
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Nepal</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Malaysia</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>India</p>
                      </div>
                    </li>

                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Dubai</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="">
                  <ul className="list-unstyled faq-one__list">
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Thailand</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Maldives</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Singapore</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqOne;
