import React from 'react'
import Mainlayout from "../../components/Mainlayout";
import Welcome from '../../components/HomeComponents/Welcome/Welcome';
import AboutPageHeader from '../../components/WhoWeAreComponents/AboutPageHeader';
import OurServices from '../../components/HomeComponents/OurServices/OurServices';
import TeamOne from '../../components/WhoWeAreComponents/TeamOne';
import CtaTwo from '../../sharedComponents/CtaTwo';
import CounterOne from "../../components/HomeComponents/CounterOne/CounterOne"

const WhoWeAre = () => {
  return (
    <div>
      <Mainlayout>
        <AboutPageHeader />
        <Welcome />
        <OurServices />
        <TeamOne />
        <CounterOne />
        <CtaTwo />
      </Mainlayout>
    </div>
  );
}

export default WhoWeAre;
