import React from 'react'
import Mainlayout from '../../components/Mainlayout';
import PageHeader from '../../sharedComponents/PageHeader';
import { images } from '../../constants';
import B2bSolutionLayout from '../../sharedComponents/B2bSolutionLayout';
import { IoCheckmark } from "react-icons/io5";
import "../../styles/Services/ServicesSolution.css"

const HotelSolution = () => {
  return (
    <div>
      <Mainlayout>
        <PageHeader
          name1={"HOME"}
          name2={"B2B"}
          MainName={"HOTEL SOLUTIONS"}
          backgroundImage={images.B2bPageHeader}
        />
        <B2bSolutionLayout>
          <div className="col-xl-8 col-lg-7">
            <div className="services-details__left">
              <div className="services-details__img">
                <img src={images.ServicesDetailsHotelImg} alt="" />
              </div>
              <div className="services-details__top-content">
                <h2 className="services-details__top-title">Hotel Solutions</h2>
                <p className="services-details__top-text">
                  Our Hotel or Hospitality management services is suitable for
                  hotels, resorts, boutique/eco hotels, hostels etc.
                </p>
              </div>
              <div className="services-details__planning">
                <div className="services-details__planning-content">
                  <h2 className="services-details__planning-title">
                    What we offer
                  </h2>
                  <p className="services-details__planning-text">
                    We offer following services to the hotel industry but not
                    limited to :
                  </p>
                  <ul className="list-unstyled services-details__planning-list">
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Front Office Management</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Food & Beverage Production/ Kitchen</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Housekeeping</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Engineering</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Reservations</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>
                          Food & Beverage Services / Restaurant Management /
                          Outlet Management
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Banquets & Convention</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Hotel Operations & Management</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Sales & Marketing</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </B2bSolutionLayout>
      </Mainlayout>
    </div>
  );
}

export default HotelSolution;
