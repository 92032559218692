import React from "react";
import Mainlayout from "../../components/Mainlayout";
import PageHeader from "../../sharedComponents/PageHeader";
import { images } from "../../constants";
import B2bSolutionLayout from "../../sharedComponents/B2bSolutionLayout";
import { IoCheckmark } from "react-icons/io5";
import "../../styles/Services/ServicesSolution.css";

const DigitalSolution = () => {
  return (
    <div>
      <Mainlayout>
        <PageHeader
          name1={"HOME"}
          name2={"B2B"}
          MainName={"DIGITAL SOLUTIONS"}
          backgroundImage={images.B2bPageHeader}
        />
        <B2bSolutionLayout>
          <div className="col-xl-8 col-lg-7">
            <div className="services-details__left">
              <div className="services-details__img">
                <img src={images.ServicesDetailImg6} alt="" />
              </div>
              <div className="services-details__top-content">
                <h2 className="services-details__top-title">
                  DIGITAL SOLUTIONS
                </h2>
                <p className="services-details__top-text">
                  We provide latest digital infrastructure, implement latest
                  technologies to improve market presence, sales and branding of
                  hospitality sectors.
                </p>
              </div>
              <div className="services-details__planning">
                <div className="services-details__planning-content">
                  <h2 className="services-details__planning-title">
                    WHAT WE OFFER
                  </h2>
                  <p className="services-details__planning-text">
                    We offer digital services and solutions to achieve the
                    following but not limited to :
                  </p>
                  <ul className="list-unstyled services-details__planning-list">
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Sales & Marketing</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Branding</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Latest Hospitality Gadgets</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Website & SEO</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Digital Campaigns</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>
                          Implementing Digital Platform for Accounting & Billing
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Digital Marketing & Promotions</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </B2bSolutionLayout>
      </Mainlayout>
    </div>
  );
};

export default DigitalSolution;
