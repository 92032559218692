import React from "react";
import Mainlayout from "../../components/Mainlayout";
import PageHeader from "../../sharedComponents/PageHeader";
import { images } from "../../constants";
import MessageBox from "../../components/ContactUsComponents/MessageBox";
import Location from "../../components/ContactUsComponents/Location";
import GoogleMap from "../../components/ContactUsComponents/GoogleMap";

const ContactUs = () => {
  return (
    <div>
      <Mainlayout>
        <PageHeader
          name1={"HOME"}
          name2={"CONTACT"}
          MainName={"CONTACT US"}
          backgroundImage={images.B2bPageHeader}
        />
        <MessageBox />
        <Location />
        <GoogleMap/>
      </Mainlayout>
    </div>
  );
};

export default ContactUs;
