import React from "react";
import PageHeader from "../../sharedComponents/PageHeader";
import { images } from "../../constants";

const AboutPageHeader = () => {
  return (
    <>
      <PageHeader name1={"HOME"} name2={"ABOUT"} MainName={"WHO WE ARE"} backgroundImage={images.AboutPageHeaderBg} />
    </>
  );
};

export default AboutPageHeader;
