import React from "react";
import Header from "./Navbar/Header";
import Footer from "./Footer";
import CTA from "./CTA";
import BrandTree from "./BrandTree";
import StickyHeader from "./Navbar/StickyHeader";

const Mainlayout = ({ children }) => {
  return (
    <div>
      <Header />
       <StickyHeader />
      {children}
      <BrandTree />
      <CTA />
      <Footer />
    </div>
  );
};

export default Mainlayout;
