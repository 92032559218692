import React from "react";
import Header from "./Header";
import "../../styles/StickyHeader.css";

const StickyHeader = () => {
    const [isSticky, setIsSticky] = React.useState(false);

    React.useEffect(() => {
      const handleScroll = () => {
        const scrollThreshold = 110;
        const shouldStick = window.scrollY > scrollThreshold;
        setIsSticky(shouldStick);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  return (
    <div className={`sticky-header ${isSticky ? "show" : ""}`}>
      <Header backgroundColor="#0f0d1d" position="fixed" />
    </div>
  );
};

export default StickyHeader;
