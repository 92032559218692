import React from "react";
import "../../../styles/Home/CasesOne.css";
import { images } from "../../../constants";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { Bounce, Flip, Roll, Zoom } from "react-reveal";

const CasesOne = () => {
  return (
    <section className="cases-one" style={{ marginTop: 0 }}>
      <div className="container">
        <div className="section-title text-center">
          <h2 className="section-title__title">
            OUR EXCEPTIONAL B2B SOLUTIONS
          </h2>
          <span className="section-title__tagline">
            We manage & serve any hospitality businesses.
          </span>
        </div>
        <div className="row">
          <Fade bottom>
            <div className="col-xl-4 col-lg-4">
              <div className="cases-one__single">
                <div className="cases-one__img-box">
                  <div className="cases-one__img">
                    <img src={images.case1Img3} alt="" />
                  </div>
                  <div className="cases-one__content">
                    <div className="cases-one__icon">
                      <span>
                        <img src={images.hotel} alt="" />
                      </span>
                    </div>
                    <h2 className="cases-one__title">
                      <Link to={"/hotel-solutions"}>HOTELS SOLUTION</Link>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="col-xl-4 col-lg-4">
              <div className="cases-one__single">
                <div className="cases-one__img-box">
                  <div className="cases-one__img">
                    <img src={images.case1Img1} alt="" />
                  </div>
                  <div className="cases-one__content">
                    <div className="cases-one__icon">
                      <span>
                        <img src={images.Restaurant} alt="" />
                      </span>
                    </div>
                    <h2 className="cases-one__title">
                      <Link to={"/restaurant-solutions"}>
                        RESTAURANT SOLUTIONS
                      </Link>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="col-xl-4 col-lg-4">
              <div className="cases-one__single">
                <div className="cases-one__img-box">
                  <div className="cases-one__img">
                    <img src={images.case1Img2} alt="" />
                  </div>
                  <div className="cases-one__content">
                    <div className="cases-one__icon">
                      <span>
                        <img src={images.Food} alt="" />
                      </span>
                    </div>
                    <h2 className="cases-one__title">
                      <Link to={"/food-solutions"}>FOOD SOLUTION</Link>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="col-xl-4 col-lg-4">
              <div className="cases-one__single">
                <div className="cases-one__img-box">
                  <div className="cases-one__img">
                    <img src={images.case1Img4} alt="" />
                  </div>
                  <div className="cases-one__content">
                    <div className="cases-one__icon">
                      <span>
                        <img src={images.HR} alt="" />
                      </span>
                    </div>
                    <h2 className="cases-one__title">
                      <Link to={"/hr-solutions"}>HR SOLUTION</Link>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="col-xl-4 col-lg-4">
              <div className="cases-one__single">
                <div className="cases-one__img-box">
                  <div className="cases-one__img">
                    <img src={images.case1Img5} alt="" />
                  </div>
                  <div className="cases-one__content">
                    <div className="cases-one__icon">
                      <span>
                        <img src={images.Hospitality} alt="" />
                      </span>
                    </div>
                    <h2 className="cases-one__title">
                      <Link to={"/hospitality-consulting"}>
                        HOSPITALITY CONSULTING
                      </Link>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="col-xl-4 col-lg-4">
              <div className="cases-one__single">
                <div className="cases-one__img-box">
                  <div className="cases-one__img">
                    <img src={images.case1Img6} alt="" />
                  </div>
                  <div className="cases-one__content">
                    <div className="cases-one__icon">
                      <span>
                        <img src={images.Digital} alt="" />
                      </span>
                    </div>
                    <h2 className="cases-one__title">
                      <Link to={"/digital-solutions"}>DIGITAL SOLUTION</Link>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default CasesOne;
