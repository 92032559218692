import React from "react";
import "../styles/CTA.css";
import { Link } from "react-router-dom";

const CTA = () => {
  return (
    <section className="cta-one">
      <div className="cta-one-bg"></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="cta-one_inner">
              <div className="cta-one_left">
                <h2 className="cta-one_title">
                  WE’RE DELIVERING THE BEST <br />
                  HOSPITALITY CONSULTING EXPERIENCE
                </h2>
              </div>
              <div className="cta-one_right">
                <Link to={"/contact-us"} className="thm-btn .cta-one_btn">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA;
