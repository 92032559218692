import React from "react";
import "../../styles/AboutTeam.css";
import { images } from "../../constants";
import { IoCheckmark } from "react-icons/io5";

const ServiceDetails = () => {
  return (
    <section className="services-details">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="section-title text-center">
              <h2 className="section-title__title">OUR TEAM</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div id="puskar-shrestha" className="services-details__left pb-5">
              <div className="services-details__planning">
                <div className="services-details__planning-img">
                  <img src={images.TeamOne1} alt="" />
                </div>
                <div className="services-details__planning-content">
                  <h2
                    className="services-details__planning-title"
                    style={{ marginBottom: "10px" }}>
                    PUSKAR SHRESTHA
                  </h2>
                  <h5 style={{ marginBottom: "10px" }}>
                    <b style={{ color: "#04b0b6" }}>CEO, Entrepreneur</b>
                  </h5>
                  <h5 style={{ marginBottom: "20px" }}>Kathmandu, Nepal</h5>
                  <p
                    className="services-details__planning-text"
                    style={{ textAlign: "justify" }}>
                    Namaste, I have been in the hospitality industry since 2012,
                    after I completed my diploma in culinary and hospitality
                    management from Nepal. I am a Culinarian by profession and
                    have experince in National & International hotels and their
                    franchises like Hotel Yak & Yeti Nepal, Radission Blu Hotel
                    Qatar, Furaveri Maldives Resort Maldives etc.
                  </p>
                </div>
              </div>
            </div>
            <div id="sanjay-udas" className="services-details__left pb-5">
              <div className="services-details__planning">
                <div className="services-details__planning-img">
                  <img src={images.TeamOne2} alt="" />
                </div>
                <div className="services-details__planning-content">
                  <h2
                    className="services-details__planning-title"
                    style={{ marginBottom: "10px" }}>
                    SANJAY UDAS
                  </h2>
                  <h5 style={{ marginBottom: "10px" }}>
                    <b style={{ color: "#04b0b6" }}>
                      MD & Head of Digital Branding
                    </b>
                  </h5>
                  <h5 style={{ marginBottom: "20px" }}>Kathmandu, Nepal</h5>
                  <p
                    className="services-details__planning-text"
                    style={{ textAlign: "justify" }}>
                    Hello! Since 1998, I have been in media fraternity as
                    Digital and Graphic Head in plathora of reputed media houses
                    like Image Channel, Sagarmatha, Bodhi TV etc
                  </p>
                </div>
              </div>
            </div>
            <div id="sujan-maharjan" className="services-details__left pb-5">
              <div className="services-details__planning">
                <div className="services-details__planning-img">
                  <img src={images.TeamOne3} alt="" />
                </div>
                <div className="services-details__planning-content">
                  <h2
                    className="services-details__planning-title"
                    style={{ marginBottom: "10px" }}>
                    SUJAN MAHARJAN
                  </h2>
                  <h5 style={{ marginBottom: "10px" }}>
                    <b style={{ color: "#04b0b6" }}>
                      Culinary Incharge & Consultant
                    </b>
                  </h5>
                  <h5 style={{ marginBottom: "20px" }}>Kritipur, Nepal</h5>
                  <p
                    className="services-details__planning-text"
                    style={{ textAlign: "justify" }}>
                    Namaste, in 2012, I graduted BHM and joined the kitchen of
                    Crown & Champa in Maldives. I spent more than 6 years in
                    several property in Maldives like Komandoo Island Resort,
                    Hurawalhi Island Resort, Kudadoo Private Island Resport etc
                    as a Chef de Partie and culinary instructor.
                  </p>
                </div>
              </div>
            </div>
            <div id="shikar-sjb" className="services-details__left pb-5">
              <div className="services-details__planning">
                <div className="services-details__planning-img">
                  <img src={images.TeamOne4} alt="" />
                </div>
                <div className="services-details__planning-content">
                  <h2
                    className="services-details__planning-title"
                    style={{ marginBottom: "10px" }}>
                    SHIKHAR SJB RANA
                  </h2>
                  <h5 style={{ marginBottom: "10px" }}>
                    <b style={{ color: "#04b0b6" }}>F&B Head, Entrepreneur</b>
                  </h5>
                  <h5 style={{ marginBottom: "20px" }}>Kathmandu, Nepal</h5>
                  <p
                    className="services-details__planning-text"
                    style={{ textAlign: "justify" }}>
                    Hello, I studied Hospitality Management from the University
                    of Singapore in 2002 and have worked in the finest stars
                    property in Nepal like Hotel Yak & Yeti, Hotel Shangri~la ,
                    Hotel Aakama etc as a Food and Beverage Manager carrying
                    more than a decades of experience in the hospitality sector.
                  </p>
                </div>
              </div>
            </div>
            <div id="ibharim-imad" className="services-details__left pb-5">
              <div className="services-details__planning">
                <div className="services-details__planning-img">
                  <img src={images.TeamOne5} alt="" />
                </div>
                <div className="services-details__planning-content">
                  <h2
                    className="services-details__planning-title"
                    style={{ marginBottom: "10px" }}>
                    IBHARIM IMAD
                  </h2>
                  <h5 style={{ marginBottom: "10px" }}>
                    <b style={{ color: "#04b0b6" }}>
                      Advisor & Hospitality Expertise
                    </b>
                  </h5>
                  <h5 style={{ marginBottom: "20px" }}>Maldives</h5>
                  <ul className="list-unstyled services-details__planning-list">
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>MD of ZH Aluminium</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>
                          Graduation & MBA on Hospitality & Tourism from
                          Maldives University in 2000
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>
                          20+ years of experience in Hospitality fraternity in
                          Maldives
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Entrepreneur</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div id="mohamed-riyaz" className="services-details__left pb-5">
              <div className="services-details__planning">
                <div className="services-details__planning-img">
                  <img src={images.TeamOne6} alt="" />
                </div>
                <div className="services-details__planning-content">
                  <h2
                    className="services-details__planning-title"
                    style={{ marginBottom: "10px" }}>
                    MOHAMED RIYAZ
                  </h2>
                  <h5 style={{ marginBottom: "10px" }}>
                    <b style={{ color: "#04b0b6" }}>BDH & Culinary Director</b>
                  </h5>
                  <h5 style={{ marginBottom: "20px" }}>Maldives</h5>
                  <ul className="list-unstyled services-details__planning-list">
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>
                          Graduation on Hospitality & Tourism from International
                          Universities
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>
                          28 years of experience in culinary, beverages and
                          management levels of hospitality sector in Maldives.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Expertise in F&B operations, Kitchen Operations</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>
                          Worked as Head of Operations in various star
                          properties of Maldives.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-tick">
                          <IoCheckmark />
                        </span>
                      </div>
                      <div className="text">
                        <p>Entrepreneur</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceDetails;
