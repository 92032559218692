import React from "react";
import images from "../../constants/images";
import "../../styles/Services/B2cCasestudy.css";

const B2cCasestudy = () => {
  return (
    <div>
      <section
        className="cases-details"
        style={{ paddingTop: "0px", paddingBottom: "60px" }}>
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="cases-details__img">
                <img src={images.CasesDetail} alt="" />
              </div>
            </div>
          </div>
          <div id="vocational" className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="cases-details__left">
                <div className="cases-details__content">
                  <div className="cases-details__icon">
                    <span className="icon-consultant">
                      <img src={images.consultantPng} height={65} alt="" />
                    </span>
                  </div>
                  <h2 className="cases-details__title">Vocational Education</h2>
                  <p className="cases-details__text-1">
                    We prepare young and interested men and women with relevant
                    hospitality knowledge, skills and attitudes to successfully
                    enter the world of hospitality work or continue higher
                    education.
                  </p>
                  <p className="cases-details__text-2">
                    Our vocational education includes national and international
                    courses in hospitality and other skill sectors like cook,
                    commercial cookery, waiter, barista, bartender, food &amp;
                    beverage, mason, plumber etc. Our Culinary Courses are
                    recognized by Nepal Government's CTEVT and Australia while
                    our Barista courses are certifed from UK and Europe. While
                    rest of our vocational courses are credited by Nepal
                    Government.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div id="abroad" className="row " style={{ paddingTop: "30px" }}>
            <div className="col-xl-12 col-lg-12">
              <div className="cases-details__left">
                <div className="cases-details__content">
                  <div className="cases-details__icon">
                    <span className="icon-consulting">
                      <img src={images.consultingPng} height={65} alt="" />
                    </span>
                  </div>
                  <h2 className="cases-details__title">Abroad Consultation</h2>
                  <p className="cases-details__text-1">
                    We assist for abroad education, career and visa
                    consultation.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div id="jobs" className="row " style={{ paddingTop: "30px" }}>
            <div className="col-xl-12 col-lg-12">
              <div className="cases-details__left">
                <div className="cases-details__content">
                  <div className="cases-details__icon">
                    <span className="icon-business">
                      <img src={images.businessPng} height={65} alt="" />
                    </span>
                  </div>
                  <h2 className="cases-details__title">
                    100% Job &amp; Internship Assistance
                  </h2>
                  <p className="cases-details__text-1">
                    We provide jobs and internship assistance in both national
                    and international platform to interested youth upon meeting
                    the following requirements :
                  </p>
                  <ul>
                    <li>
                      {" "}
                      Free Counselling should be taken beforehand joining any
                      vocational education
                    </li>

                    <li>
                      {" "}
                      Specified Courses must be attended which lasts between 3
                      weeks to 1 year depending upon the type of subject chosen.
                    </li>

                    <li>
                      {" "}
                      If criterias are met, internship or jobs could be secured
                      after training completion or even during the training
                      period in both national and international hospitality
                      &amp; tourism sectors.{" "}
                    </li>
                  </ul>
                  <p></p>
                </div>
              </div>
            </div>
          </div>

          <div id="personal" className="row " style={{ paddingTop: "30px" }}>
            <div className="col-xl-12 col-lg-12">
              <div className="cases-details__left">
                <div className="cases-details__content">
                  <div className="cases-details__icon">
                    <span className="icon-customer-review">
                      <img src={images.customerReview} height={65} alt="" />
                    </span>
                  </div>
                  <h2 className="cases-details__title">
                    Personal Grooming &amp; Career Counselling
                  </h2>
                  <p className="cases-details__text-1">
                    We provide personal grooming and counselling along with
                    abroad consultation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default B2cCasestudy;
