import React from 'react'
import { images } from '../../../constants'
import "../../../styles/Home/Welcome.css"
import { FaQuoteLeft } from "react-icons/fa";

const Welcome = () => {
  return (
    <section className="welcome-one">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="welcome-one__left">
              <div className="welcome-one__img-box">
                <div className="welcome-one__img-1">
                  <img src={images.welcome1Img1} alt="Welcome1" />
                </div>
                <div className="welcome-one__img-2">
                  <img src={images.welcome1Img2} alt="Welcome1" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="welcome-one__right">
              <h2 class="welcome-one__title">About Dream Sailor</h2>
              <p className="welcome-one__text">
                Dream Sailor Hospitality & Consulting is a team of professionals
                with expertise in hospitality, tourism, digital world, career &
                abroad consulting, educational and vocational sector.
              </p>
              <p className="welcome-one__text">
                We provide recognized and verified hospitality courses
                fulfilling criteria of different countries such as UK, Europe,
                Nepal, Australia etc. For Nepal, we provide CTEVT certified
                courses.
              </p>
              <p className="welcome-one__text-two">
                We have 20+ years of combined experience in hospitality
                management and consulting services to businesses and
                individuals.
              </p>
              <div className="welcome-one__call">
                <div className="welcome-one__call-icon">
                    <FaQuoteLeft />
                </div>
                <div className="welcome-one__call-text">
                  <p>
                    <i>"Train the Youth, Empower the Nation"</i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Welcome
