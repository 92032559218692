import React from "react";
import { images } from "../constants";
import "../styles/CtaTwo.css";
import { Link } from "react-router-dom";
import { TiMessages } from "react-icons/ti";

const CtaTwo = () => {
  return (
    <section className="cta-two">
      <div
        className="cta-two-bg"
        style={{ backgroundImage: `url(${images.CtaTwoBg})` }}></div>
      <div className="cta-two-bg-overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="cta-two__inner">
              <h2 className="cta-two__title">MEET OUR CONSULTANTS</h2>
              <p className="cta-two__text">
                Contact us to get a free consultation
              </p>
              <ul className="list-unstyled cta-two__icon-box">
                <li>
                  <Link to={"/contact-us"}>
                    <div className="cta-two__icon">
                      <span className="icon-consulting-1">
                        <TiMessages/>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaTwo;
