import React from "react";
import "../styles/WhoWeAre/AboutHeader.css";
import { Link } from "react-router-dom";

const PageHeader = ({ name1, name2, MainName, backgroundImage }) => {
  return (
    <section className="page-header">
      <div
        className="about-page-header__bg"
        style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      <div className="container">
        <div className="page-header__inner">
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <Link>{name1}</Link>
            </li>
            <li>
              <span>/</span>
            </li>
            <li>{name2}</li>
          </ul>
          <h2>{MainName}</h2>
        </div>
      </div>
    </section>
  );
};

export default PageHeader;
