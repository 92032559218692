import React from "react";
import "../../styles/WhoWeAre/TeamOne.css";
import TeamContainer from "../../sharedComponents/TeamContainer";
import { images } from "../../constants";

const TeamOne = () => {
  return (
    <section className="team-one" id="our-team">
      <div className="team-one__container">
        <div className="section-title text-center">
          <h2 className="section-title__title">MEET THE TEAM</h2>
          <span className="section-title__tagline">
            People that bring you results
          </span>
        </div>
        <div className="row">
          <TeamContainer
            image={images.TeamOne1}
            name={"PUSKAR SHRESTHA"}
            title={"CEO"}
            location={"KATHMANDU, NEPAL"}
          />
          <TeamContainer
            image={images.TeamOne2}
            name={"SANJAY UDAS"}
            title={"MD & HEAD OF DIGITAL BRANDING"}
            location={"KATHMANDU, NEPAL"}
          />
          <TeamContainer
            image={images.TeamOne3}
            name={"SUJAN MAHARJAN"}
            title={"CULINARY INCHARGE & CONSULTANT"}
            location={"KRITIPUR, NEPAL"}
          />
          <TeamContainer
            image={images.TeamOne4}
            name={"SHIKHAR SJB RANA"}
            title={"F&B HEAD"}
            location={"KATHMANDU, NEPAL"}
          />
          <TeamContainer
            image={images.TeamOne5}
            name={"IBHARIM IMAD"}
            title={"ADVISER & HOSPITALITY EXPERTISE"}
            location={"MALDIVES"}
          />
          <TeamContainer
            image={images.TeamOne6}
            name={"MOHAMED RIYAZ"}
            title={"BDH & CULINARY DIRECTOR"}
            location={"MALDIVES"}
          />
        </div>
      </div>
    </section>
  );
};

export default TeamOne;
